// add placeholders to login input fields
const passwordField = document.querySelector('#password');
const usernameField = document.querySelector('#username');

if (passwordField) {
  passwordField.placeholder = 'Passwort';
}

if (usernameField) {
  usernameField.placeholder = 'vorname.name';
}
