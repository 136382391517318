//
// hide search if logged out based on div il-avatar
//
const userIsLoggedIn = document.querySelectorAll('.il-avatar')[0];
if (!userIsLoggedIn) {
  const searchIcons = [...document.querySelectorAll(".glyphicon-search")];
  if (searchIcons[0]) {
    searchIcons.map((searchIcon)  => {
      searchIcon.closest('button').remove();
    });
  }
}

