// add class to page based on url parameters and other conditions
(function() {

  let classname = " ";

  if (window.location.href.indexOf("login.php") > -1) {
    classname += "login-page";
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsObject = Object.fromEntries(new URLSearchParams(location.search));

    if (urlParams.has('baseClass')) {
      const noSidebarParams = ['ilAdministrationGUI'];

      if (noSidebarParams.indexOf(paramsObject.baseClass) > -1) {
        classname += ' no-sidebar ';
      }
    }

    if (urlParams.has('cmdClass')) {
      classname += urlParams.get('cmdClass') + "-page";
      const noSidebarParams = [
        'ilpermissionsgui',
        'ilgroupmembershipgui',
        'ilcoursemembershipgui'
      ];

      console.log('has cmdClass');

      if (noSidebarParams.indexOf(paramsObject.cmdClass) > -1) {
        console.log('has cmdClass: ', paramsObject.cmdClass);
        classname += ' no-sidebar ';
      }

    }

    if (urlParams.has('root')) {
      classname += " ref-id-" + urlParams.get('ref_id');
    }

    if (urlParams.has('back_cmd')) {
      classname += " -id-" + urlParams.get('ref_id');
    }

    const rightCol = document.querySelector('#il_right_col');

    if (rightCol) {
      classname += ' page-with-right-col';
    }

    // detect category page based on footer current permalink
    const currentPermalink = document.querySelector('#current_perma_link');

    if (currentPermalink && currentPermalink.value.includes('target=cat')) {
        classname += " category-page";
    }
    if (currentPermalink && currentPermalink.value.includes('target=crs')) {
        classname += " course-page";
    }
    if (currentPermalink && currentPermalink.value.includes('target=fold')) {
        classname += " folder-page";
    }
    if (currentPermalink && currentPermalink.value.includes('target=grp')) {
        classname += " group-page";
    }
  }

  if (document.getElementsByClassName("il-layout-page")[0]) {
    document.getElementsByClassName("il-layout-page")[0].className += classname;
  }
})();
